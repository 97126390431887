import React from "react";
import { Heading } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

import Layout from "../../components/Layout";
import BlogRoll from "../../components/BlogRoll";
import Container from "../../components/Container";

const BlogIndexPage = ({ ...props }) => (
  <>
    <Helmet>
      <title>Nyheter</title>
    </Helmet>
    <Layout {...props}>
      <Container py="3rem">
        <Heading textAlign="center" as="h1" size="lg" pb={6}>
          Nyheter
        </Heading>
        <BlogRoll />
      </Container>
    </Layout>
  </>
);

export default BlogIndexPage;
